/* eslint-disable react/no-deprecated */
/* eslint-disable react/button-has-type */
import React from 'react';
import s from './FiltersSubmitButton.scss';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../galleryGlobalStrategy';
import classNames from 'classnames';

export interface IFiltersSubmitButton extends IGalleryGlobalProps, IProvidedTranslationProps {
  fullWidth?: boolean;
}

class FiltersSubmitButtonComp extends React.Component<IFiltersSubmitButton> {
  private handleClick() {
    this.props.globals.applyFilteredProductsOnMobile();
  }

  public render() {
    const {
      globals: {usingExtendedGridGalleryStyles},
    } = this.props;
    const classes = classNames(s.filtersSubmitButton, {
      [s.fullWidth]: this.props.fullWidth,
      [s.usingExtendedGridGalleryStyles]: usingExtendedGridGalleryStyles,
    });

    return (
      <button className={classes} data-hook="filters-submit-button" onClick={() => this.handleClick()}>
        {this.props.t('filtersSubmitButtonText')}
      </button>
    );
  }
}

export const FiltersSubmitButton = withGlobals(withTranslations()(FiltersSubmitButtonComp));
