/* eslint-disable react/button-has-type */
import React from 'react';
import s from './FiltersFooter.scss';
import classNames from 'classnames';
import {classes, st} from './FiltersFooter.st.css';

import {FiltersSubmitButton} from '../FiltersSubmitButton/FiltersSubmitButton';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../galleryGlobalStrategy';
import {MobileFiltersPanelState} from '../../../../types/galleryTypes';
import {TextButton} from 'wix-ui-tpa/cssVars';
import {Clear} from '@wix/wix-ui-icons-common/on-stage';

export interface IFiltersFooterProps extends IGalleryGlobalProps, IProvidedTranslationProps {
  shouldShowClearFiltersButton: boolean;
  clearFilters: Function;
  filtersPanelState?: MobileFiltersPanelState;
}

class FiltersFooterComp extends React.Component<IFiltersFooterProps> {
  private clearFiltersButtonClicked() {
    this.props.clearFilters();
  }

  private renderClearFiltersButton() {
    const {
      globals: {hasSelectedFilters, shouldShowMobile, usingExtendedGridGalleryStyles},
    } = this.props;

    return usingExtendedGridGalleryStyles ? (
      <TextButton
        disabled={!hasSelectedFilters}
        data-hook="categories-clear-filters-button"
        onClick={() => this.clearFiltersButtonClicked()}
        className={classNames(
          s.clearFiltersButton,
          {
            [s.usingExtendedGridGalleryStyles]: usingExtendedGridGalleryStyles,
          },
          st(classes.textButtonRoot, {useMobileColor: shouldShowMobile})
        )}
        priority={'secondary' as any}
        suffixIcon={!shouldShowMobile && <Clear />}>
        {this.props.t('clearFiltersButtonText')}
      </TextButton>
    ) : (
      <button
        data-hook="clear-filters-button"
        onClick={() => this.clearFiltersButtonClicked()}
        className={classNames(s.clearFiltersButton, {
          [s.disabled]: !hasSelectedFilters,
        })}>
        {this.props.t('clearFiltersButtonText')}
        {!shouldShowMobile && (
          <span className={s.clearFiltersX} data-hook="clear-filters-button-x">
            X
          </span>
        )}
      </button>
    );
  }

  public render() {
    const {shouldShowMobile, usingExtendedGridGalleryStyles} = this.props.globals;
    const {shouldShowClearFiltersButton, filtersPanelState} = this.props;
    const sortOnly = filtersPanelState === MobileFiltersPanelState.SORT_ONLY;
    const shouldShowFooterDivider = usingExtendedGridGalleryStyles && shouldShowMobile;

    return (
      <div className={classNames(s.actions, {[s.footerDivider]: shouldShowFooterDivider})}>
        {shouldShowClearFiltersButton && !sortOnly && this.renderClearFiltersButton()}
        {shouldShowMobile && <FiltersSubmitButton fullWidth={sortOnly} />}
      </div>
    );
  }
}

export const FiltersFooter = withGlobals(withTranslations()(FiltersFooterComp));
