import React from 'react';
import classNames from 'classnames';
import {Button, ButtonPriority, PaddingModes} from 'wix-ui-tpa/cssVars';
import {classes, cssStates} from './CategoryListItem.st.css';
import {ICategoryListItem} from '../../../../types/category';
import {useCategories} from '../../../hooks/useCategories';
import {CategoryListLayout} from '../../../../constants';
import {useStyles} from '@wix/tpa-settings/react';
import {useStylesParams} from '../../../../stylesParamsContext';
import {useEnvironment} from '@wix/yoshi-flow-editor';

type CategoryListItemProps = {
  dataHook?: string;
  className?: string;
  category: ICategoryListItem;
  text: string;
};

export const CategoryListItem: React.FC<CategoryListItemProps> = ({
  dataHook,
  category,
  text,
  className,
}: CategoryListItemProps) => {
  const {currentCategory, navigateToCategory} = useCategories();
  const isActive = currentCategory.id === category.id;
  const styles = useStyles();
  const stylesParams = useStylesParams();
  const {isMobile} = useEnvironment();
  const verticalCategoryList =
    styles.get(stylesParams.gallery_categoryListLayout) === CategoryListLayout.Vertical && !isMobile;

  return (
    <li className={className}>
      <Button
        as="a"
        paddingMode={PaddingModes.explicitPaddings}
        priority={ButtonPriority.secondary}
        data-hook={dataHook}
        className={classNames(
          verticalCategoryList ? classes.verticalRoot : classes.horizontalRoot,
          cssStates({activeCategory: isActive})
        )}
        wrapContent={verticalCategoryList}
        href={category.categoryUrl}
        onClick={() => navigateToCategory(category.id)}>
        {text}
      </Button>
    </li>
  );
};
