/* eslint-disable sonarjs/cognitive-complexity */
import React, {useEffect, useRef, useState} from 'react';
import s from './CategoryTree.scss';
import {classes as stylableClasses, cssStates} from './CategoryTree.st.css';
import {useGlobals, withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {Text, TextButton, TextButtonPriority} from 'wix-ui-tpa/cssVars';
import {ConditionalRender} from '../../../common/components/ConditionalRender/ConditionalRender';
import {useEnvironment, useExperiments} from '@wix/yoshi-flow-editor';
import {CategoryListLayout, Experiments, GallerySlotIds} from '../../../constants';
import {ICategory, ICategoryListItem} from '../../../types/category';
import {useCategories} from '../../hooks/useCategories';
import classNames from 'classnames';
import {CategoryListHeader} from './CategoryListHeader/CategoryListHeader';
import {ChevronDown, ChevronUp} from '@wix/wix-ui-icons-common';
import {useStyles} from '@wix/tpa-settings/react';
import {useStylesParams} from '../../../stylesParamsContext';
import {CategoryListItem} from './CategoryListItem/CategoryListItem';
import {HeadingTags} from '@wix/wixstores-client-core/dist/es/src/types/heading-tags';
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi';
import {useSlots} from '../../hooks/useSlots';

export const CategoryTreeDataHook = {
  SectionTitle: 'category-tree-section-title',
  List: 'category-tree-list',
  BackButton: 'category-tree-back-button',
  ShowMoreButton: 'category-tree-show-more-button',
  CategoryHeader: 'category-tree-header',
  Container: 'category-tree-container',
  ShopAllButton: 'category-tree-shop-all-button',
  CategoryOption: {
    at: (i: number) => `category-option-${i}`,
  },
};

export const CategoryTree: React.FunctionComponent<IGalleryGlobalProps & IProvidedTranslationProps> = withGlobals(
  withTranslations()(({t, globals}: IGalleryGlobalProps & IProvidedTranslationProps) => {
    const htmlTag = globals.htmlTags.categoriesFiltersHtmlTag;
    const {experiments} = useExperiments();
    const enableNewCategoryList = experiments.enabled(Experiments.EnableStorefrontSubCategories);

    const {isMobile} = useEnvironment();
    let categories: ICategoryListItem[];
    let currentCategory: ICategory;
    let listHeaderCategory: ICategoryListItem;
    const styles = useStyles();
    const stylesParams = useStylesParams();
    const categoryListLayout = styles.get(stylesParams.gallery_categoryListLayout);
    const isVerticalLayout =
      (categoryListLayout === CategoryListLayout.Vertical && !isMobile) || !enableNewCategoryList;
    const [categoryListCollapsed, setCategoryListCollapsed] = useState<boolean>(isVerticalLayout);
    let textsMap;
    let categoryTreeTitle: string;
    let categoriesFiltersHtmlTag: HeadingTags;
    if (enableNewCategoryList) {
      const {categoryList, listHeaderCategory: listHeader, currentCategory: _currentCategory} = useCategories();
      listHeaderCategory = listHeader;
      categories = categoryList;
      currentCategory = _currentCategory;
      categoriesFiltersHtmlTag = useGlobals().htmlTags.categoriesFiltersHtmlTag;
      categoryTreeTitle = useGlobals().textsMap.categoryTreeTitle;
      textsMap = useGlobals().textsMap;
    } else {
      categories = globals.categories;
      currentCategory = globals.currentCategory;
    }

    const categoryListRef = useRef<HTMLUListElement>();

    /* istanbul ignore next - hard to test with js dom */
    useEffect(() => {
      if (!categoryListRef.current) {
        return;
      }

      const {offsetTop: categoryListTop, clientHeight: categoryListHeight} = categoryListRef.current;
      const categoryListBottomPosition = categoryListTop + categoryListHeight;
      const categoryListItemElements = [...categoryListRef.current.querySelectorAll('li')] as HTMLLIElement[];

      categoryListItemElements.forEach((li) => ((li.firstChild as any).tabIndex = 0));
      const overflowHiddenListItems = categoryListItemElements.filter(
        (li) => li.offsetTop > categoryListBottomPosition
      );

      overflowHiddenListItems.forEach((li) => ((li.firstChild as any).tabIndex = -1));
    }, [categoryListRef, categoryListCollapsed, categories]);

    const onCategoryClicked = (e, destinationCategoryId: string, destinationLink: string) => {
      const {handleCategoryClick} = globals;
      e.preventDefault();
      handleCategoryClick({destinationCategoryId, destinationLink});
    };

    const shouldIndentCategoryList = enableNewCategoryList && listHeaderCategory;
    const inlineCssVars = enableNewCategoryList ? {'--totalNumberOfCategories': categories.length} : {};

    const {isSlotUsed} = useSlots();
    const topSlotUsed = isSlotUsed(GallerySlotIds.CategoryPageListTop);

    return (
      <>
        {enableNewCategoryList && (
          <div className={classNames({[s.topSlot]: topSlotUsed})}>
            <SlotsPlaceholder slotId={GallerySlotIds.CategoryPageListTop} />
          </div>
        )}
        <div
          className={classNames(s.root, {
            [s.newCategoryListEnabled]: enableNewCategoryList,
            [s.horizontalLayout]: !isVerticalLayout,
          })}
          data-hook={CategoryTreeDataHook.Container}>
          {isVerticalLayout && (
            <>
              <ConditionalRender by={'gallery_showCategoriesTitle'}>
                <Text
                  data-hook={CategoryTreeDataHook.SectionTitle}
                  tagName={enableNewCategoryList ? categoriesFiltersHtmlTag : htmlTag}>
                  <span className={s.sectionTitle}>
                    {enableNewCategoryList ? categoryTreeTitle : t('categoryTreeTitle')}
                  </span>
                </Text>
              </ConditionalRender>
              {enableNewCategoryList && listHeaderCategory && (
                <ConditionalRender by={'gallery_showCategoryListHeader'} className={s.listHeader}>
                  <CategoryListHeader dataHook={CategoryTreeDataHook.CategoryHeader} />
                </ConditionalRender>
              )}
            </>
          )}
          <div
            data-hook={CategoryTreeDataHook.List}
            style={inlineCssVars as React.CSSProperties}
            className={s.listContainer}>
            <ul
              ref={categoryListRef}
              className={classNames(s.categoryList, {
                [s.categoryListCollapsed]: categoryListCollapsed,
                [s.shouldIndent]: shouldIndentCategoryList,
              })}>
              {enableNewCategoryList && listHeaderCategory && (
                <ConditionalRender by={'gallery_showCategoryListShopAll'} className={s.listItem}>
                  <CategoryListItem
                    key={listHeaderCategory.id}
                    dataHook={CategoryTreeDataHook.ShopAllButton}
                    category={listHeaderCategory}
                    text={textsMap.categoryListShopAllButton}
                  />
                </ConditionalRender>
              )}
              {categories.map((category, i) =>
                enableNewCategoryList ? (
                  <CategoryListItem
                    key={category.id}
                    className={s.listItem}
                    dataHook={CategoryTreeDataHook.CategoryOption.at(i)}
                    category={category}
                    text={category.name === 'All Products' ? textsMap.allProducts : category.name}
                  />
                ) : (
                  <li
                    key={category.id}
                    className={classNames(s.categoryListItem, {[s.shouldIndent]: shouldIndentCategoryList})}>
                    <TextButton
                      data-hook={CategoryTreeDataHook.CategoryOption.at(i)}
                      as="a"
                      className={classNames(
                        stylableClasses.option,
                        cssStates({
                          activeCategory: currentCategory.id === category.id,
                        })
                      )}
                      priority={TextButtonPriority.secondary}
                      href={category.categoryUrl}
                      onClick={(event) => onCategoryClicked(event, category.id, category.categoryUrl)}>
                      {category.name === 'All Products' ? t('allProducts') : category.name}
                    </TextButton>
                  </li>
                )
              )}
            </ul>
            {enableNewCategoryList && isVerticalLayout && (
              <ConditionalRender by={'shouldLimitCategoryList'}>
                <TextButton
                  suffixIcon={categoryListCollapsed ? <ChevronDown /> : <ChevronUp />}
                  data-hook={CategoryTreeDataHook.ShowMoreButton}
                  onClick={() => setCategoryListCollapsed(!categoryListCollapsed)}
                  className={classNames(stylableClasses.showMoreCategoriesTextButtonRoot, s.showMore, {
                    [s.shouldIndent]: shouldIndentCategoryList,
                  })}>
                  {categoryListCollapsed ? textsMap.categoryListShowMore : textsMap.categoryListShowLess}
                </TextButton>
              </ConditionalRender>
            )}
          </div>
        </div>
      </>
    );
  })
);
