import React, {useCallback, useEffect, useMemo, useState} from 'react';
import type {IFilterOption, IRangeValue} from '../../../../../types/galleryTypes';
import {IGalleryGlobalProps} from '../../../../galleryGlobalStrategy';
import {PriceSlider} from '../PriceSlider/PriceSlider';
import {withGlobals} from '../../../../../globalPropsContext';

export interface IRangeFilterProps extends IGalleryGlobalProps {
  filterId: number;
  options: IFilterOption[];
  selectedRange: {
    min: string;
    max: string;
  };
}

export const RangeFilterComp: React.FC<IRangeFilterProps> = (props) => {
  const {options, selectedRange, globals, filterId} = props;

  const [minIndex, setMinIndex] = useState(options.findIndex((o) => o.key === selectedRange.min));
  const [maxIndex, setMaxIndex] = useState(options.findIndex((o) => o.key === selectedRange.max));

  const minIndexLimit = 0;
  const maxIndexLimit = options.length - 1;

  useEffect(() => {
    const findIndexOrDefault = (key: string | number, defaultIndex: number) => {
      const index = options.findIndex((o) => o.key === key);
      return index !== -1
        ? index
        : /* istanbul ignore next: difficult to test, will be tested with sled */ defaultIndex;
    };

    const calculatedMinIndex = findIndexOrDefault(selectedRange.min, minIndexLimit);
    const calculatedMaxIndex = findIndexOrDefault(selectedRange.max, maxIndexLimit);

    setMinIndex(calculatedMinIndex);
    setMaxIndex(calculatedMaxIndex);
  }, [options, selectedRange.min, selectedRange.max, minIndexLimit, maxIndexLimit]);

  const values = useMemo(() => ({min: minIndex, max: maxIndex}), [minIndex, maxIndex]);

  const formatLabel = useCallback(
    (_: any, type: 'min' | 'max') => {
      const valueIndex = values[type];
      const option = options[valueIndex?.toString()];
      return option?.value;
    },
    [options, values]
  );

  const onChange = useCallback(
    ({min, max}: IRangeValue) => {
      setMinIndex(min);
      setMaxIndex(max);
    },
    [setMinIndex, setMaxIndex]
  );

  const onChangeComplete = (range: IRangeValue) => {
    const filterProducts = globals.filterProducts;

    const newValues = {
      min: options[range.min].key,
      max: options[range.max].key,
    };

    filterProducts(filterId, newValues);
  };

  return (
    <PriceSlider
      minAriaLabel={globals.textsMap.priceRangeMinSR}
      maxAriaLabel={globals.textsMap.priceRangeMaxSR}
      formatLabel={formatLabel}
      minValue={minIndexLimit}
      maxValue={maxIndexLimit}
      onChange={onChange}
      onChangeComplete={onChangeComplete}
      value={values}
      usingExtendedGridGalleryStyles={globals.usingExtendedGridGalleryStyles}
    />
  );
};

export const RangeFilter = withGlobals(RangeFilterComp);
