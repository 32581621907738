/* istanbul ignore file */
import {createStylesParams, StyleParamType} from '@wix/tpa-settings';
import {baseStylesParams} from '../../styleParams/baseStylesParams';
import {IStylesParams, StylesParamKeys} from '../../styleParams/types';
import {CustomCssVarsFn} from '@wix/yoshi-flow-editor';
import {baseCustomCssVars, CustomCssVarsFnParams} from '../../baseCustomCssVars';

export const customCssVars: CustomCssVarsFn = (params: CustomCssVarsFnParams) => ({
  ...baseCustomCssVars(params),
});
const stylesParams: IStylesParams = {
  ...baseStylesParams,
  gallery_layoutHorizontalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: ({isMobile}) => (isMobile ? 10 : 0),
  },
};

export default createStylesParams<StylesParamKeys>(stylesParams);
