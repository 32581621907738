import React from 'react';
import type {DropdownOptionProps} from 'wix-ui-tpa';
import {classes, st} from './Sort.st.css';
import {classes as floatingClasses} from './FloatingSort.st.css';
import s from './Sort.scss';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../globalPropsContext';
import type {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import classNames from 'classnames';
import {LazyDropDown, LazyFloatingDropDown} from '../../lazyComponents/lazyWUTComponents';
import {ISortingOption} from '../../../types/sorting';

export enum DataHook {
  SelectedIOptionIcon = 'selected-option-icon',
  SortDropdown = 'sort-dropdown',
  FloatingSortDropdown = 'sort-floating-dropdown',
}

interface SortProps {
  isFloatingView?: boolean;
}

class SortComp extends React.Component<SortProps & IGalleryGlobalProps & IProvidedTranslationProps> {
  private readonly getOptions = (sortingOptions: ISortingOption[]) => {
    return sortingOptions.map((o) => ({
      isDisabled: false,
      isSelectable: true,
      id: o.id,
      value: this.getOptionText(o),
    }));
  };

  private readonly getOptionText = (o) => {
    return this.props.t(o.titleKey);
  };

  private readonly onSelect = (option: DropdownOptionProps, sortOptions: ISortingOption[]) => {
    const sortingOption = sortOptions.find((o) => o.id === option.id);
    this.props.globals.sortProducts(sortingOption);
  };

  private readonly onExpandedChange = (isExpanded) => {
    if (!isExpanded) {
      return;
    }

    this.props.globals.sendSortClickBiEvent();
  };

  public render = () => {
    const {selectedSort, sortingOptions, totalProducts, usingExtendedGridGalleryStyles} = this.props.globals;
    const {isFloatingView, t} = this.props;

    return (
      <div
        className={classNames({[s.sortWrapper]: !isFloatingView}, {[s.floatingSortWrapper]: isFloatingView}, s.root)}>
        {isFloatingView ? (
          <LazyFloatingDropDown
            label={t('sortLabel')}
            value={selectedSort.id}
            onExpandedChange={this.onExpandedChange}
            data-hook={DataHook.FloatingSortDropdown}
            className={st(
              floatingClasses.sort,
              classNames(s.selector, {[s.usingExtendedGridGalleryStyles]: usingExtendedGridGalleryStyles})
            )}
            onChange={(option: DropdownOptionProps) => this.onSelect(option, sortingOptions)}
            placement={'bottom'}
            options={this.getOptions(sortingOptions)}
            dynamicWidth
            disabled={totalProducts === 0}
          />
        ) : (
          <LazyDropDown
            onExpandedChange={this.onExpandedChange}
            data-hook={DataHook.SortDropdown}
            className={st(classes.sort, s.selector)}
            initialSelectedId={selectedSort.id}
            onChange={(option: DropdownOptionProps) => this.onSelect(option, sortingOptions)}
            placement={'bottom'}
            options={this.getOptions(sortingOptions)}
          />
        )}
      </div>
    );
  };
}

export const Sort = withGlobals(withTranslations()(SortComp));
