import {useGlobals} from '../../globalPropsContext';
import {Experiments} from '../../constants';
import {useExperiments} from '@wix/yoshi-flow-editor';

export const useSlots = () => {
  const {experiments} = useExperiments();
  const enableSlotsInGallery = experiments.enabled(Experiments.RenderSlotsInGallery);
  const {slots} = useGlobals();

  const isSlotUsed = (slotId: string) => {
    return enableSlotsInGallery && Boolean(slots?.[slotId]);
  };

  return {isSlotUsed};
};
