/* istanbul ignore file */
import React, {useEffect, useRef, useState} from 'react';
import cx from 'classnames';
import s from './FadeIn.scss';

export const FadeIn: React.FunctionComponent<{children?: any}> = (props: {children?: any}) => {
  const [fadeInState, setState] = useState({isVisible: true, itemWasFadedIn: false});
  const [intersectionObserver, setIntersectionObserver] = useState(null);
  const domRef = useRef(null);
  const domRefCurrent = domRef.current;

  useEffect(() => {
    if (!intersectionObserver) {
      setIntersectionObserver(
        new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                setState({isVisible: true, itemWasFadedIn: true});
              } else if (!entry.isIntersecting && !fadeInState.itemWasFadedIn) {
                setState({isVisible: false, itemWasFadedIn: false});
              }
            });
          },
          {
            threshold: 0.1,
          }
        )
      );
    }

    if (domRefCurrent && intersectionObserver && !fadeInState.itemWasFadedIn) {
      intersectionObserver.observe(domRefCurrent);
      return () => intersectionObserver.unobserve(domRefCurrent);
    }
  }, [fadeInState.isVisible, fadeInState.itemWasFadedIn, intersectionObserver, domRefCurrent]);

  const classNames = cx(s.fadeInSection, {
    [s.isVisible]: fadeInState.isVisible,
  });

  return (
    <div className={classNames} ref={domRef}>
      {props.children}
    </div>
  );
};
