import React, {CSSProperties, useCallback, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import s from './Clamp.scss';
import {TextButton, TextButtonPriority} from 'wix-ui-tpa/cssVars';
import {useEnvironment} from '@wix/yoshi-flow-editor';

export enum ClampDataHook {
  ReadMoreButton = 'ClampDataHook.ReadMoreButton',
  ReadLessButton = 'ClampDataHook.ReadLessButton',
  HiddenTextCopy = 'ClampDataHook.HiddenTextCopy',
}

export type ClampProps = {
  enabled: boolean;
  text: string;
  className: string;
  readMoreLessLinkClassName: string;
  readMoreText: string;
  readLessText: string;
  maxLines: number;
  handleClampClick?: Function;
  dataHook?: string;
};

// istanbul ignore next: cant test with jsdom, should be tested by sled
export const Clamp: React.FunctionComponent<ClampProps> = ({
  children,
  text,
  className,
  readMoreLessLinkClassName,
  readMoreText,
  readLessText,
  enabled,
  dataHook,
  maxLines,
  handleClampClick,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const [isClamped, setIsClamped] = useState(true);
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [descriptionWidth, setDescriptionWidth] = useState(0);
  const [shouldShowReadMoreLessLink, setShouldShowReadMoreLessLink] = useState(false);
  const textRef = useRef<HTMLSpanElement>(null);
  const ellipsisRef = useRef<HTMLSpanElement>(null);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const [textToDisplay, setTextToDisplay] = useState<string>(text);
  const {isMobile} = useEnvironment();

  useEffect(() => {
    void document.fonts.ready.then(() => setFontsLoaded(true));
  }, []);

  useEffect(() => {
    if (!enabled || !descriptionRef.current) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      entries?.forEach((entry) => setDescriptionWidth(entry.contentRect.width));
    });

    const descriptionElement = descriptionRef.current;
    observer.observe(descriptionElement);

    return () => {
      if (descriptionElement) {
        observer.unobserve(descriptionElement);
      } else {
        observer.disconnect();
      }
    };
  }, [enabled, descriptionRef]);

  function isMaxHeightExceeded(maxHeight: number) {
    const currentTextHeight = textRef.current.getBoundingClientRect().height;
    return currentTextHeight > maxHeight;
  }

  const calculateMaxAllowedHeight = useCallback(() => {
    const lineHeight = parseFloat(getComputedStyle(textRef.current).lineHeight) || 1;
    const readMoreLineHeight = parseFloat(getComputedStyle(textRef.current.lastElementChild).lineHeight) || 1;
    return lineHeight * (maxLines - 1) + Math.max(lineHeight, readMoreLineHeight);
  }, [maxLines]);

  useEffect(() => {
    if (!enabled || !fontsLoaded || !textRef.current) {
      return;
    }

    const hiddenTextNodes = getHiddenTextNodes();
    const maxAllowedHeight = calculateMaxAllowedHeight();

    if (!isMaxHeightExceeded(maxAllowedHeight)) {
      return;
    }

    while (isMaxHeightExceeded(maxAllowedHeight)) {
      const lastTextNode = hiddenTextNodes.pop();
      textRef.current?.removeChild(lastTextNode);
    }

    const remainingNodesTextContent = hiddenTextNodes.map((node) => node.textContent).join('');
    setTextToDisplay(`${remainingNodesTextContent.trimEnd()}…`);
    setShouldShowReadMoreLessLink(true);
    setTimeout(() => descriptionRef.current?.style?.setProperty('-webkit-line-clamp', 'unset'), 0);
  }, [text, maxLines, fontsLoaded, isMobile, enabled, descriptionRef, descriptionWidth, calculateMaxAllowedHeight]);

  function getHiddenTextNodes() {
    const allChildNodes = [...(textRef.current?.childNodes ?? [])];
    const ellipsisIndex = allChildNodes.findIndex((x) => x === ellipsisRef.current);
    return allChildNodes.slice(0, ellipsisIndex);
  }

  function splitTextBySpaceAndMaxLength() {
    // eslint-disable-next-line prefer-named-capture-group
    const textParts = text.split(/(\s+)/);
    for (let i = 0; i < textParts.length; i++) {
      if (textParts[i].length <= 10) {
        continue;
      }

      const pattern = /.{1,5}/gs;
      const splitByMaxNumberOfChars = textParts[i].match(pattern);
      textParts.splice(i, 1, ...splitByMaxNumberOfChars);
    }

    return textParts;
  }

  const ReadMoreLink = () => (
    <TextButton
      className={readMoreLessLinkClassName}
      priority={TextButtonPriority.link}
      onClick={() => {
        setIsClamped(!isClamped);
        handleClampClick?.(!isClamped);
      }}
      data-hook={isClamped ? ClampDataHook.ReadMoreButton : ClampDataHook.ReadLessButton}>
      {isClamped ? readMoreText : readLessText}
    </TextButton>
  );

  function HiddenTextCopy() {
    const textRefKey = textRef.current?.parentElement.clientWidth;
    const splitText = splitTextBySpaceAndMaxLength();

    return (
      <div className={classNames(s.textContainer, s.hidden, className)} data-hook={ClampDataHook.HiddenTextCopy}>
        <span ref={textRef} key={textRefKey} className={s.splitTextWrapper}>
          {splitText.map((part, i) => (
            <span key={i}>{part}</span>
          ))}
          <span ref={ellipsisRef}>{'… '}</span>
          <TextButton className={readMoreLessLinkClassName} priority={TextButtonPriority.link}>
            {readMoreText}
          </TextButton>
        </span>
      </div>
    );
  }

  const nonClampedHtml = (
    <div className={classNames(s.textContainer, className)} data-hook={dataHook}>
      {children}
    </div>
  );

  const style = {'--max-lines': maxLines} as CSSProperties;
  const clampedHtml = (
    <div className={s.clampContainer}>
      <div
        className={classNames(s.textContainer, className, {[s.clamp]: isClamped})}
        data-hook={dataHook}
        ref={descriptionRef}
        style={style}>
        {isClamped ? textToDisplay : text}
        {
          /*istanbul ignore next: cant test with jsdom, will be tested by sled*/ shouldShowReadMoreLessLink && (
            <>
              {' '}
              <ReadMoreLink />
            </>
          )
        }
      </div>
      <HiddenTextCopy />
    </div>
  );

  return enabled ? clampedHtml : nonClampedHtml;
};
