import React from 'react';
import type {IFilterOption, IRangeValue} from '../../../../../types/galleryTypes';
import {IGalleryGlobalProps} from '../../../../galleryGlobalStrategy';
import {PriceSlider} from '../PriceSlider/PriceSlider';
import {withGlobals} from '../../../../../globalPropsContext';

export interface IRangeFilterProps extends IGalleryGlobalProps {
  filterId: number;
  options: IFilterOption[];
  selectedRange: {
    min: string;
    max: string;
  };
}

interface IRangeFilterState {
  minIndex: number;
  maxIndex: number;
}

export class RangeFilterComp extends React.Component<IRangeFilterProps, IRangeFilterState> {
  public state = {
    minIndex: this.props.options.findIndex((o) => o.key === this.props.selectedRange.min),
    maxIndex: this.props.options.findIndex((o) => o.key === this.props.selectedRange.max),
  };

  public formatLabel = (_, type: 'min' | 'max') => {
    return this.values[type] === undefined ? '' : this.props.options[this.values[type]?.toString()]?.value;
  };

  public onChange = ({min, max}: IRangeValue) => {
    this.setState({
      minIndex: min,
      maxIndex: max,
    });
  };

  public onChangeComplete = (range: IRangeValue) => {
    const {
      filterId,
      options,
      globals: {filterProducts},
    } = this.props;

    const values = {
      min: options[range.min].key,
      max: options[range.max].key,
    };

    filterProducts(filterId, values);
  };

  private get minIndex() {
    return 0;
  }

  private get maxIndex() {
    return this.props.options.length - 1;
  }

  private get values() {
    return {
      min: this.state.minIndex,
      max: this.state.maxIndex,
    };
  }

  public render() {
    return (
      <PriceSlider
        minAriaLabel={this.props.globals.textsMap.priceRangeMinSR}
        maxAriaLabel={this.props.globals.textsMap.priceRangeMaxSR}
        formatLabel={this.formatLabel}
        minValue={this.minIndex}
        maxValue={this.maxIndex}
        onChange={this.onChange}
        onChangeComplete={this.onChangeComplete}
        value={this.values}
        usingExtendedGridGalleryStyles={this.props.globals.usingExtendedGridGalleryStyles}
      />
    );
  }
}

export const RangeFilterClass = withGlobals(RangeFilterComp);
